import React, { useState, useMemo } from "react"
import orderBy from "lodash/orderBy"
import styled from "styled-components"

import useCities from "../hooks/use-cities"
import Layout from "../components/Layout"
import CityCard from "../components/CityCard"
import SEO from "../components/seo"
import CustomLink from "../components/CustomLink"
// import CityMap from "../components/CityMap"
// import FilterControls from "../components/FilterControls"
// import FilterCitiesProvider from "../providers/FilteredCitiesProvider"
import withLocation from "../containers/withLocation"

export const ExplorePageTemplate = ({
  children,
  renderMap = true,
  isPreview = false,
}) => (
  <Wrapper isPreview={false}>
    <section className="cities" id="cities">
      <div className="cities__inner">
        <div className="container">{children}</div>
      </div>
    </section>
  </Wrapper>
)

const CitiesPage = withLocation(({ location, search: query }) => {
  const cities = useCities()
  const sortedCities = orderBy(cities, ["title"], ["asc"])
  const [searchInput, setSearchInput] = useState(query.search || "")
  const filteredCities = useMemo(() => {
    return sortedCities.filter(city => {
      if (!searchInput) return true
      if (
        city.summary.display_name &&
        city.summary.display_name
          .toUpperCase()
          .indexOf(searchInput.toUpperCase()) !== -1
      )
        return true
      if (
        city.title &&
        city.title.toUpperCase().indexOf(searchInput.toUpperCase()) !== -1
      )
        return true
      if (
        city.summary.country_name &&
        city.summary.country_name
          .toUpperCase()
          .indexOf(searchInput.toUpperCase()) !== -1
      )
        return true
      return false
    })
  }, [cities])

  return (
    <Layout className="explore">
      <SEO title="Explore Cities" />
      <ExplorePageTemplate>
        <h2 className="cities__title">Explore all participating cities</h2>
        <div className="center">
          <SearchInput>
            <input
              type="text"
              name="city"
              id=""
              placeholder="Search"
              value={searchInput}
              onChange={e => setSearchInput(e.target.value)}
            />
          </SearchInput>
        </div>
        <div className="city-list">
          {filteredCities.map(
            ({ slug, title, summary, latitude, longitude }) => (
              <CustomLink
                className="city-list__item"
                key={slug}
                href={`/cities/${slug}/`}
              >
                <CityCard
                  title={title}
                  {...summary}
                  latitude={latitude}
                  longitude={longitude}
                />
              </CustomLink>
            )
          )}
        </div>
      </ExplorePageTemplate>
    </Layout>
  )
})

const Wrapper = styled.div`
  .center {
    display: flex;
    justify-content: center;
  }
  .page-header {
    .map-header {
      position: relative;
      height: 24rem;
      background: #a0dedf;

      @media (min-width: ${({ theme }) => theme.mobile}) {
        height: 27rem;
      }

      @media (min-width: ${({ theme }) => theme.desktop}) {
        height: 43rem;
      }

      .map-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }

    .filter-controls {
      position: relative;
      width: 100%;
      z-index: 3;

      .container {
        padding: 0;

        @media (min-width: ${({ theme }) => theme.tablet}) {
          height: 0;
          padding: 0 3.125rem;
        }

        .filter-controls-wrapper {
          @media (min-width: ${({ theme }) => theme.tablet}) {
            transform: translate(0, calc(-100% - 1.75rem));

            body.is-ie & {
              transform: translate(0, -100%);
              margin-top: -1.75rem;
            }
          }
        }
      }
    }
  }

  .cities {
    padding-top: 8rem;
    margin-top: -8rem;

    &__inner {
      padding: 2rem 0 2.5rem;
      background: ${({ theme }) => theme.offWhite};

      @media (min-width: ${({ theme }) => theme.mobile}) {
        padding: 3rem 0 5rem;
      }
    }

    &__title {
      margin-bottom: 1.5rem;
      text-align: center;

      @media (min-width: ${({ theme }) => theme.mobile}) {
        margin-bottom: 2rem;
      }
    }
  }

  .city-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.125rem;

    @media (min-width: ${({ theme }) => theme.mobile}) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: ${({ theme }) => theme.desktop}) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: ${({ theme }) => theme.largeDesktop}) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    * {
      text-decoration: none;
    }

    body.is-ie & {
      margin: 0 -0.5625rem;

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 0;
        clear: both;
      }

      .city-list__item {
        margin-bottom: 1.125rem;

        @media (min-width: ${({ theme }) => theme.mobile}) {
          width: 50%;
          float: left;
          padding: 0 0.5625rem;
        }

        @media (min-width: ${({ theme }) => theme.tablet}) {
          width: 33.33%;
        }

        @media (min-width: ${({ theme }) => theme.desktop}) {
          width: 25%;
        }
      }
    }
  }
`

const SearchInput = styled.div`
  input {
    text-align: left;
    border-radius: 1.25rem;
    background-color: white;
    padding: 0.6rem 1.5rem;
    padding-right: 2.5rem;
    border: 2px solid #62656e;
    height: 40px;
    width: 180px;
    font-family: ${({ theme }) => theme.fonts["roboto"]};
    font-weight: bold;
  }

  display: inline-block;
  position: relative;
  margin: 0 auto 2rem;

  /* border-right: none; */
  /* border-top-right-radius: 0;
  border-bottom-right-radius: 0; */

  &:hover {
    cursor: text;
    &::after {
      opacity: 0.7;
    }
  }

  &::after {
    display: block;
    content: "";
    position: absolute;
    right: 0.5rem;
    top: 0;
    bottom: 0;
    height: 100%;
    aspect-ratio: 1/1;
    background-image: url(/img/loupe.svg);
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    opacity: 0.3;
    transition: all ease 0.3s;
    /* color: #62656e; */
  }
`

export default CitiesPage
